import React from "react"
import styled from "styled-components"

const BandcampStyle = styled.iframe`
  height: ${({ height }) => height};
  display: block;
  width: ${({ width }) => width};
  @media (max-width: 650px) {
    width: 90vw;
  }
  flex: 1;
`

const Bandcamp = ({
  width = "350px",
  height = "120px",
  tracklist = "false",
}) => (
  <BandcampStyle
    src={`https://bandcamp.com/EmbeddedPlayer/album=3367756048/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=${tracklist}/artwork=small/`}
    seamless
    loading="lazy"
    width={width}
    height={height}
  >
    <a href="https://johannmcbee.bandcamp.com/album/precious-moments">
      Precious Moments by Johann McBee
    </a>
  </BandcampStyle>
)

export default Bandcamp
