import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../components/LayoutComponent"
import HangdrumImg from "../components/images/HangdrumImg"
import Content from "../components/Content"
import Bandcamp from "../components/Bandcamp"
import Seo from "../components/Seo"

const HPTextContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const HomepageContent = styled.section`
  flex: 1 0 300px;
`

const BandcampContainer = styled.div`
  flex: 1 0 100px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media (min-width: 568px) {
    margin: 0 0 0 1.5rem;
  }
`

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <Content>
        <HangdrumImg/>
        <HPTextContainer className="test">
          <HomepageContent>
            <p>
              Welcome to the website for performing artist, musician, and
              accredited music therapist Johann McBee.
            </p>

            <p>
              On this site you will find audio and video demos of my music, an
              artist's biography and links to my other social media sites
              including Bandcamp and Instagram, as well as a brief overview of
              my music therapy practice and contact info.
            </p>

            <p>
              If you are interested in booking entertainment for your event
              please inquire via the <Link to="/contact">contact form</Link> for
              availability and rates.
            </p>
          </HomepageContent>
          <BandcampContainer>
            <Bandcamp />
          </BandcampContainer>
        </HPTextContainer>
      </Content>
    </Layout>
  )
}

export default IndexPage
