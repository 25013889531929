import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HangdrumImg = () => 
    <StaticImage
      src="../../images/hangdrum-background.jpg"
      alt="hangdrum"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />

export default HangdrumImg
